import { serverURL, handleRequest } from '../helpers/server';
const querystring = require('querystring');

export const storeService = {
  plans,
  extract,
  extractByCorporate,
  historic,
  requestTransaction,
  processeAdvancedPurchase
};

async function plans () {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    timeout: 6000
  };
  return await handleRequest(`${serverURL()}/portal/v1/plans`, requestOptions);
}

async function extract (params) {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    timeout: 6000
  };
  return await handleRequest(`${serverURL()}/portal/v1/transaction/statement?${querystring.stringify(params)}`, requestOptions);
}

async function extractByCorporate (corporateId, params) {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    timeout: 6000
  };
  return await handleRequest(`${serverURL()}/portal/v1/transaction/${corporateId}/statement?${querystring.stringify(params)}`, requestOptions);
}

async function historic () {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    timeout: 6000
  };
  return await handleRequest(`${serverURL()}/portal/v1/user/historic`, requestOptions);
}

async function requestTransaction (userData) {
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    body: JSON.stringify(userData),
    timeout: 6000
  };
  return await handleRequest(`${serverURL()}/portal/v1/transaction/credit`, requestOptions);
}

async function processeAdvancedPurchase (userData) {
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    body: JSON.stringify(userData),
    timeout: 6000
  };
  return await handleRequest(`${serverURL()}/portal/v1/transaction/advanced`, requestOptions);
}
